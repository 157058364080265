

@font-face {
  font-family: 'sf_bold';
  src: url('../src/assets/fonts/sf_pro_rounded_bold.otf'); 
}

@font-face {
  font-family: 'sf_black';
  src: url('../src/assets/fonts/sf_pro_rounded_black.otf'); 
}

@font-face {
  font-family: 'sf_med';
  src: url('../src/assets/fonts/sf_pro_rounded_medium.otf'); 
}
@font-face {
  font-family: 'sf_reg';
  src: url('../src/assets/fonts/sf_pro_rounded_regular.otf'); 
}


*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }


  body{
      font-family: 'sf_reg', sans-serif;
      /* border: 5px solid cyan; */
      width: 100%;
  }
 


 .gradient-fade {
      background-image: linear-gradient(to top, transparent, #ebf0f2f7, transparent);
    }

.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

    .price_item{
       font-family: 'sf_bold', sans-serif;
    }

  .h2{
    font-weight:'800';
  }

  .custom-bold{
    font-family: 'sf_bold', sans-serif;
    font-weight:'800';
  }

  .post_title{
    font-family: 'sf_med', sans-serif;
  }

  .p_title{
     font-family: 'sf_med', sans-serif; 
  }

  .post_user_name{
      font-family: 'sf_med', sans-serif;
      font-weight: 300;
  }

  .regular_font{
     font-family: 'sf_reg', sans-serif;
     font-weight: 500; 
  }
  
  /* Horizontal scrollbar styles */
  ::-webkit-scrollbar {
      height: 0px;
      width: 8px;
    }
    
    ::-webkit-scrollbar-thumb {
      background-color:#F0F0F0;
    }
    
    ::-webkit-scrollbar-track {
      background-color: dodgerblue;
    }
    
    .no-scroll-bar::-webkit-scrollbar{
      width: 1px;
    }
  




    .category-scroll-icon,.sub-category-item  {
      border-radius: 999px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }

    select option {
      margin-bottom: 10px;
      -webkit-border-bottom: 1px solid #ccc;
      -moz-border-bottom: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
    }
    
    .min_wrapper {
      /* display: grid; */
      grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
      grid-gap: 10px;
    } 
    
    .min_wrapper > .p_wrapper { 
     word-break: break-all; 
    }

    
  /* Style for the clear/CANCEL IN SEARCH BOX button */
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 1em;
  width: 1em;
  border-radius: 50em;
  background: url(https://pro.fontawesome.com/releases/v5.10.0/svgs/solid/times-circle.svg) no-repeat 50% 50%;
  background-size: contain;
  opacity: 0;
  pointer-events: none;
  cursor: pointer;
}

input[type="search"]:focus::-webkit-search-cancel-button {
  opacity: .3;
  pointer-events: all;
}

/* style the list elemets in static pages */

 .eng ul>li{
margin: 8px 0;
 }