


.carousel .control-next.control-arrow, .carousel .control-next.control-arrow:hover{
    color: black;
    border: none;
    outline: none;
    background: #2B9EDA;
    height: 40px;
    width: 40px;
   border-radius: 50%;
   top: 40%;
   display: flex;
   justify-content: center;
   align-items: center;
}

.carousel .control-prev.control-arrow, .carousel .control-prev.control-arrow:hover {
    color: black;
    border: none;
    outline: none;
    background:#2B9EDA;
    height: 40px;
    width: 40px;
   border-radius: 50%;
   top: 40%;
   display: flex;
   justify-content: center;
   align-items: center;
   
}



.carousel .control-dots .dot {
    background-color: white;
  }

.carousel .slide{
    height: 100%;
}
 